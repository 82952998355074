import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../../components/custom-select/CustomSelect";
import NepaliCalender from "../../../../components/nepali-calender";
import { EXPENSES_TYPE, REQUIRED_FIELD } from "../../../../utils/constants/index";
import styles from "./style";

const AddStaffMedicalExpensesDetails = ({ onMedicalExpensesDetailsSubmit, modalDefaultValue }) => {
  const classes = styles();
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    register({ name: "amountTakenDate" }, { required: true });
    register({ name: "expensesType" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (modalDefaultValue) {
      setValue("amountTakenDate", modalDefaultValue?.amountTakenDate);
      setValue("expensesType", modalDefaultValue?.expensesType);
    }
  }, [])

  const handleExpensesTypeChange = (name, value) => {
    setValue(name, value)
  }

  const handleDateChange = (name, dateInMilli) => {
    setValue(name, dateInMilli);
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <form onSubmit={handleSubmit(onMedicalExpensesDetailsSubmit)}>
        <Box>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs>
              <Box>
                <CustomSelect
                  id="expensesType"
                  name="expensesType"
                  label="उपचार"
                  options={EXPENSES_TYPE}
                  onChange={handleExpensesTypeChange}
                  defaultValue={modalDefaultValue?.expensesType}
                />
                {errors.expensesType && <span className="error-message">{REQUIRED_FIELD}</span>}
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <NepaliCalender
                  defaultDate={modalDefaultValue?.amountTakenDate}
                  id="amountTakenDate"
                  name="amountTakenDate"
                  label="उपचार खर्च लिएको मिति"
                  onChange={handleDateChange}
                />
                {errors.amountTakenDate && <span className="error-message">{REQUIRED_FIELD}</span>}
              </Box>
            </Grid>
            <Grid item xs>
              <TextField
                defaultValue={modalDefaultValue?.amountTaken}
                label="लिएको रकम"
                name="amountTaken"
                type="number"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                defaultValue={modalDefaultValue?.verifiedBy}
                label="प्रमाणित गर्ने"
                name="verifiedBy"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                defaultValue={modalDefaultValue?.remark}
                label="कैफियत"
                name="remark"
                type="text"
                variant="outlined"
                size="small"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box item="true" textAlign="right" className={classes.btnContainer}>
          {
            !modalDefaultValue && (
              <Button
                type="reset"
                color="secondary"
                variant="contained"
                className={classes.resetBtn}
              >
                रद्द गर्नुहोस
              </Button>
            )
          }
          <Button type="submit" color="primary" variant="contained">
            {modalDefaultValue ? "रेकर्ड अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Paper>
  )
}

export default AddStaffMedicalExpensesDetails;
